import {
  DAY_ORDER,
  DEFAULT_LANGUAGE,
  DOC_TYPES,
  LANGUAGES,
  LOCATIONS_SLUG,
  MENU_SLUG,
  PRIVATE_DINING_SECTION_ID_SUBSTRING,
  TIME_ZONES,
} from '@/data'
import { getPage } from '@/data/sanity'
import { ResolvedMetadata } from 'next'
import { ResolvedOpenGraph } from 'next/dist/lib/metadata/types/opengraph-types'
import { sendGTMEvent } from '@next/third-parties/google'
import { geti18nText } from '@/hooks/use-i18n'
import { SectionsSchema } from '@/types/components/SectionsProps'

export const GTM_ID = process.env.NEXT_PUBLIC_GTM_ID || ''

export const wait = (ms = 0) => {
  return new Promise(resolve => setTimeout(resolve, ms))
}

export const lerp = (currentValue: number, targetValue: number, ease = 0.1) => {
  return currentValue + (targetValue - currentValue) * ease
}

export const getMenuItemListingGroupId = (slug: string) => `id_${slug}`

export const setBodyCursor = (cursor: 'pointer' | 'drag' | 'dragging' | null) => {
  document.body.dataset.cursor = cursor || 'normal'
}

export const waitForWebfonts = (fonts: string[], callback: () => void) => {
  let loadedFonts = 0

  function loadFont(font: string) {
    let node: HTMLSpanElement | null = document.createElement('span')
    // Characters that vary significantly among different fonts
    node.innerHTML = 'giItT1WQy@!-/#'
    // Visible - so we can measure it - but not on the screen
    node.style.position = 'absolute'
    node.style.left = '-10000px'
    node.style.top = '-10000px'
    // Large font size makes even subtle changes obvious
    node.style.fontSize = '300px'
    // Reset any font properties
    node.style.fontFamily = 'sans-serif'
    node.style.fontVariant = 'normal'
    node.style.fontStyle = 'normal'
    node.style.fontWeight = 'normal'
    node.style.letterSpacing = '0'
    document.body.appendChild(node)

    // Remember width with no applied web font
    const width = node.offsetWidth

    node.style.fontFamily = font

    let interval: ReturnType<typeof setInterval> | null = null

    function checkFont() {
      // Compare current width with original width
      if (node && node.offsetWidth != width) {
        ++loadedFonts
        if (node.parentNode) {
          node.parentNode.removeChild(node)
        }

        node = null
      }

      // If all fonts have been loaded
      if (loadedFonts >= fonts.length) {
        if (interval) {
          clearInterval(interval)
        }
        if (loadedFonts == fonts.length) {
          callback()
          return true
        }
      }
    }

    if (!checkFont()) {
      interval = setInterval(checkFont, 50)
    }
  }

  for (let i = 0, l = fonts.length; i < l; ++i) {
    loadFont(fonts[i])
  }
}

export const getCssVar = (variable: string) => {
  return window.getComputedStyle(document.body).getPropertyValue(`--${variable}`)
}

export const formatBytes = (bytes: number, decimals = 2) => {
  if (!+bytes) return { unit: 'Bytes', size: 0 }

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return {
    size: parseFloat((bytes / Math.pow(k, i)).toFixed(dm)),
    unit: sizes[i],
  }
}

export const bytesToMb = (bytes: number) => {
  const { size, unit } = formatBytes(bytes)

  let value: number = size

  if (unit === 'KB') {
    value = value / 1024
  }

  return value
}

export const buildIdFromText = (input: string) => {
  return input
    .trim()
    .replace(/[^\w-\s+]/g, '')
    .replace(/\s+/g, '-')
    .toLowerCase()
}

export const generateMetadataReturn = ({
  title,
  description,
  shareImageUrl,
  parentData,
  allowCrawlers = true,
  themeColor = '#ffffff',
}: {
  title?: string
  description?: string
  shareImageUrl?: string
  allowCrawlers?: boolean
  parentData?: ResolvedMetadata
  themeColor?: string
}) => {
  const shareImagesParent = parentData?.openGraph?.images
  const imageChild = shareImagesParent?.length ? (shareImagesParent[0] as ResolvedOpenGraph) : null

  return {
    title: title || parentData?.title?.absolute,
    description: description || parentData?.description,
    metadataBase: new URL(process.env.NEXT_PUBLIC_SITE_URL || ''),
    themeColor,
    manifest: '/manifest.webmanifest',
    openGraph: {
      title: title || parentData?.title?.absolute,
      description: description || parentData?.description,
      images: [`${shareImageUrl ? shareImageUrl : imageChild ? imageChild.url : shareImageUrl}`],
    },
    robots: {
      index: allowCrawlers,
      follow: allowCrawlers,
      nocache: !allowCrawlers,
      googleBot: {
        index: allowCrawlers,
        follow: allowCrawlers,
        noimageindex: !allowCrawlers,
      },
    },
  }
}

export const proxyAssetCdn = (url: string) => {
  if (
    typeof url === 'string' &&
    process.env.NEXT_PUBLIC_SANITY_ASSET_HOST &&
    process.env.NEXT_PUBLIC_PROXY_ASSET_HOST &&
    process.env.NEXT_PUBLIC_SANITY_PROJECT_ID &&
    url.includes(process.env.NEXT_PUBLIC_SANITY_ASSET_HOST)
  ) {
    const proxyUrl = url
      .replace(process.env.NEXT_PUBLIC_SANITY_ASSET_HOST, process.env.NEXT_PUBLIC_PROXY_ASSET_HOST)
      .replace(`${process.env.NEXT_PUBLIC_SANITY_PROJECT_ID}/`, '')

    return proxyUrl
  }
  return url
}

export const getImageUrl = (
  image: SanityImage,
  {
    width = null,
    height = null,
    fit = null,
    quality = 80,
    invert = false,
    fm = 'webp',
    sat,
    rect = null,
  }: SanityImageOptions,
) => {
  if (!image?.asset?.url) {
    console.warn('No image.asset.url in image object supplied. ', image)
    return null
  }

  let url = image.asset.url

  const params = []
  if (width) params.push(`w=${width}`)
  if (height) params.push(`h=${height}`)
  if (quality) params.push(`q=${quality}`)
  if (invert) params.push(`invert=${invert}`)
  if (fm) params.push(`fm=${fm}`)
  if (sat) params.push(`sat=${sat}`)
  if (rect) params.push(`rect=${rect}`)
  if (fit) params.push(`fit=${fit}`)

  url = `${url}?${params.join('&')}`
  const newUrl = proxyAssetCdn(url)
  return newUrl as string
}

export const mergeSiteSettings = (pageData: SanityPage, settingsMetaData: SanityMetadata | null) => {
  if (!settingsMetaData) return pageData

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const ignoreNulls: any = {}
  if (pageData?.metadata) {
    Object.keys(pageData?.metadata).forEach(key => {
      if (pageData?.metadata[key as keyof SanityMetadata] !== null) {
        if (key === 'image' && !pageData?.metadata?.image?.asset?.url) return
        ignoreNulls[key as keyof SanityMetadata] = pageData?.metadata[key as keyof SanityMetadata]
      }
    })
  }

  const merged: SanityMetadata = {
    image: settingsMetaData.image,
    favicon: settingsMetaData.favicon,
    metaBackgroundColorHex: settingsMetaData.metaBackgroundColorHex,
    themeColorHex: settingsMetaData.themeColorHex,
    ...ignoreNulls,
    defaultSiteKeywords: settingsMetaData.keywords,
    defaultSiteTitle: settingsMetaData.title,
    defaultSiteDescription: settingsMetaData.description,
  }

  if (pageData?._type === 'location' && pageData?.locationData?.hours) {
    if (typeof pageData.locationData.hours === 'string') {
      pageData.locationData.hours = JSON.parse(pageData.locationData.hours)
    }
  }

  if (merged.image?.asset?.url) {
    merged.image.asset.url = proxyAssetCdn(merged.image.asset.url)
  }
  if (merged.favicon?.asset?.url) {
    merged.favicon.asset.url = proxyAssetCdn(merged.favicon.asset.url)
  }

  pageData.metadata = merged

  return pageData
}

export const formatPageSections = (sections: SectionsSchema) => {
  if (!sections?.length) return sections

  // eslint-disable-next-line
  const sectionsFormatted: SectionsSchema = []

  // eslint-disable-next-line
  sections.forEach((section: any) => {
    if (section.draft) {
      sectionsFormatted.push(section.draft)
    } else {
      sectionsFormatted.push(section)
    }
  })

  return sectionsFormatted as SectionsSchema
}

export const formatSiteSettingsResponse = (response: SanitySiteSettingsResponse) => {
  if (!response) return {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formatted: any = {}

  response?.forEach(setting => {
    if (!formatted[setting._type]) {
      formatted[setting._type] = setting
    }
  })

  return formatted
}

export const getLocationSlug = (location: string) => `home-${location}`

export const getPagePathBySlug = (slug: string) => {
  let locationKey: string | null = null
  Object.values(LANGUAGES).forEach(location => {
    if (getLocationSlug(location) === slug) {
      locationKey = location
    }
  })

  if (locationKey) {
    return `/${locationKey}`
  }

  return `/${slug}`
}

export const getMenuItemPathBySlug = (slug: string) => {
  return `/menu/${slug}`
}

export const getLocationPathBySlug = (slug: string) => {
  return `/locations/${slug}`
}

export const getBlogPostPathBySlug = (slug: string) => {
  return `/blog/${slug}`
}

export const getUrlFromPageData = (pageType: string, slug: string): string => {
  let url = ''

  switch (pageType) {
    case DOC_TYPES.PAGE:
      url = getPagePathBySlug(slug)
      break
    case DOC_TYPES.MENU_ITEM:
      url = getMenuItemPathBySlug(slug)
      break
    case DOC_TYPES.LOCATION:
      url = getLocationPathBySlug(slug)
      break
    case DOC_TYPES.BLOG_POST:
      url = getBlogPostPathBySlug(slug)
      break
    default:
      break
  }

  return url
}

export const generateOrgStructuredDataSchema = ({
  companyTitle,
  companyDescription,
  image,
  addressInfo,
  telephone,
  foundingDate,
  numberOfEmployees,
  email,
  otherLinks,
}: SanityOrgStructuredData) => {
  const data = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: companyTitle,
    description: companyDescription,
    url: process.env.NEXT_PUBLIC_SITE_URL,
    logo: image.asset.url,
    foundingDate: foundingDate,
    address: {
      '@type': 'PostalAddress',
      streetAddress: addressInfo.streetAddress,
      addressLocality: addressInfo.locality,
      addressRegion: addressInfo.region,
      postalCode: addressInfo.postalCode,
      addressCountry: addressInfo.country,
    },
    contactPoint: {
      '@type': 'ContactPoint',
      telephone: `[+${telephone}]`,
      email: email,
    },
    numberOfEmployees,
    sameAs: otherLinks,
  }

  return JSON.stringify(data)
}

export const getDeviceInfo = () => {
  const isBrowser = typeof window !== 'undefined'

  /* eslint-disable */
  const detect = {
    device: {},
    browser: {},
    os: {},
    bots: {},
    isTouchDevice: isBrowser && ('ontouchstart' in window || navigator.maxTouchPoints > 0),
  } as {
    device: any
    browser: any
    os: any
    bots: any
    isTouchDevice: boolean
  }

  if (isBrowser) {
    detect.device = require('@jam3/detect').device
    detect.browser = require('@jam3/detect').browser
    detect.os = require('@jam3/detect').os
    detect.bots = require('@jam3/detect').bots
  }

  /* eslint-disable */

  return detect
}

export const deviceInfo = getDeviceInfo()

export const getMenuPagePreviewKey = (type: string, slug: string) => `${type}_${slug}`

export const simpleImagesPreload = ({
  urls,
  onComplete,
  onProgress,
}: {
  urls: string[]
  onComplete?: () => void
  onProgress?: (percent: number, url: string) => void
}) => {
  let loadedCounter = 0
  const toBeLoadedNumber = urls.length

  urls.forEach(function (url) {
    preloadImage(url, function () {
      loadedCounter++
      if (onProgress) {
        onProgress(loadedCounter / toBeLoadedNumber, url)
      }
      if (loadedCounter === toBeLoadedNumber) {
        if (onComplete) onComplete()
      }
    })
  })

  function preloadImage(url: string, anImageLoadedCallback: () => void) {
    const img = new Image()
    img.onload = anImageLoadedCallback
    img.src = url
  }
}

export const calculateVhSizing = () => {
  // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
  let vh = window.innerHeight * 0.01

  // avoid android bouncing by calculating it this way
  const deviceInfo = getDeviceInfo()
  const isMobileChrome = deviceInfo.device.type === 'mobile' && deviceInfo.browser.chrome
  if (isMobileChrome) {
    vh = document.documentElement.clientHeight * 0.01
  }

  // Then we set the value in the --vh custom property to the root of the document
  document.documentElement.style.setProperty('--vh', `${vh}px`)

  // Set the difference below
  document.documentElement.style.setProperty('--navigation-height', `${window.outerHeight - window.innerHeight}px`)
}

export const formatMetadata = (
  metadata: SanityMetadata | null | undefined,
  pageData?: SanityPage | null,
  language: string = DEFAULT_LANGUAGE,
) => {
  if (!metadata || !metadata.image || !metadata.favicon) return {}

  // Title
  let pageTitle = metadata.title || pageData?.title
  if (pageData?._type === DOC_TYPES.LOCATION && !metadata.title) {
    pageTitle = geti18nText(language, 'titleAndRestaurantText', { title: pageData.title }) as string
  }

  if (!metadata.defaultSiteTitle) {
    metadata.defaultSiteTitle = '404'
  }

  let title = `${pageTitle} | ${metadata.defaultSiteTitle}`
  if (pageTitle === metadata.defaultSiteTitle) {
    title = metadata.defaultSiteTitle || ''
  }

  // Description
  let description = metadata.defaultSiteDescription
  if (metadata.description) {
    description = metadata.description
  }
  if (pageData?._type === 'menuItem' && !metadata.description && pageData?.menuItemData?.description) {
    description = pageData.menuItemData?.description
  }

  // Keywords
  let keywords = metadata.defaultSiteKeywords
  if (metadata.keywords) {
    keywords = metadata.keywords
  }

  const data: any = {
    title,
    robots: {
      index: metadata.allowCrawlers,
      follow: metadata.allowCrawlers,
      nocache: !metadata.allowCrawlers,
      googleBot: {
        index: metadata.allowCrawlers,
        follow: metadata.allowCrawlers,
        noimageindex: !metadata.allowCrawlers,
      },
    },
    metadataBase: new URL(process.env.NEXT_PUBLIC_SITE_URL || ''),
    description,
    keywords: keywords?.split(','),
    openGraph: {
      title,
      description,
      siteName: metadata.defaultSiteTitle,
      images: [
        {
          url: getImageUrl(metadata.image, { width: 1200, height: 630, fit: 'crop' }) || '',
          width: 1200,
          height: 630,
          alt: metadata.title,
        },
      ],
      type: 'website',
    },
    twitter: {
      card: 'summary_large_image',
      title,
      description,
      images: [
        {
          url: getImageUrl(metadata.image, { width: 1024, height: 512, fit: 'crop' }) || '',
          width: 1024,
          height: 512,
          alt: metadata.title,
        },
      ],
    },
  }

  return data
}

export const getFormattedMetadata = async (slug: string, docType: string, isDraftMode: boolean, language: string) => {
  const data = await getPage(slug, docType, isDraftMode, language)
  const metadata = data?.metadata

  return formatMetadata(metadata, data as SanityPage, language)
}

export const CHARS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
export const ID_LENGTH = 10
export const createRandomString = (length = ID_LENGTH) => {
  const chars = CHARS
  let result = ''
  for (let i = 0; i < length; i++) {
    result += chars.charAt(Math.floor(Math.random() * chars.length))
  }
  return result
}

export const getSections = (data: SanityPage, language: string) => {
  if (data._type === 'page') {
    return data.sections
  }

  if (data._type === 'menuItem' || data._type === 'location' || data._type === 'blogPost') {
    const newSections: any[] = []

    if (data._type === 'menuItem') {
      const title = data?.title || ''

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let allowedStaggeredImages: any[] = []
      let combinedImages: SanityImage[] = []

      if (data?.menuItemData?.lifestyleImages?.length) {
        combinedImages = [...combinedImages, ...data?.menuItemData?.lifestyleImages]
      }

      if (data?.menuItemData?.images?.length) {
        combinedImages = [...combinedImages, ...data?.menuItemData?.images]
      }

      const mainImage = combinedImages[0]

      if (data?.menuItemData?.lifestyleImages?.length) {
        data?.menuItemData?.lifestyleImages.forEach(image => {
          if (image?.asset?.url !== mainImage?.asset?.url) {
            allowedStaggeredImages.push(image)
          }
        })
      }

      allowedStaggeredImages = allowedStaggeredImages?.map(image => ({ mediaType: 'image', image }))

      const menuItemHero = {
        section: [
          {
            _type: 'textAndImageHeroWithBreadcrumbs',
            _id: 'textAndImageHeroWithBreadcrumbs',
            cmsSettings: {
              isHidden: false,
              cmsTitle: '',
            },
            sectionBackground: 'dough' as SectionBackground,
            title: title,
            subtitle: data?.menuItemData?.subtitle || '',
            description: data?.menuItemData?.description || '',
            images: combinedImages,
            slug: data?.slug?.current,
            mediaStyle: data?.menuItemData?.heroImageStyle || 'roundedCorners',
            breadcrumbLink1: {
              label: geti18nText(language, 'menu'),
              linkType: 'internal',
              link: {
                _type: DOC_TYPES.PAGE,
                slug: 'menu',
              },
            },
            breadcrumbLink2: {
              label: data?.menuItemData?.category?.title || title,
              linkType: 'internal',
              link: {
                _type: DOC_TYPES.PAGE,
                slug: MENU_SLUG,
              },
              hash: data?.menuItemData?.category?.slug?.current
                ? getMenuItemListingGroupId(data?.menuItemData?.category?.slug?.current as string)
                : null,
              navigationOffset: true,
            },
            staggeredImages: data?.staggeredMediaContent?.items?.length
              ? data?.staggeredMediaContent?.items
              : allowedStaggeredImages,
            cta: {
              label: geti18nText(language, 'findALocation'),
              linkType: 'internal',
              link: {
                _type: DOC_TYPES.PAGE,
                slug: 'locations',
              },
            },
          },
        ],
      }

      newSections.push(menuItemHero)

      if (data?.textAndImageContent?.isEnabled) {
        let defaultLink: SanityLink = {
          label: geti18nText(language, 'menuTextAndImageDefaultLinkTitle'),
          linkType: 'internal',
          link: {
            slug: LOCATIONS_SLUG,
            _type: DOC_TYPES.PAGE,
          },
        }

        if (data?.textAndImageContent?.cta?.length) {
          defaultLink = data?.textAndImageContent?.cta[0]
        }

        const isArch =
          data?.menuItemData?.heroImageStyle === 'square' || data?.menuItemData?.heroImageStyle === 'roundedCorners'

        const textAndImageData = {
          section: [
            {
              _type: 'textAndImage',
              _id: '29348698236',
              cmsSettings: {
                isHidden: false,
                cmsTitle: 'menu-item-text-and-image',
              },
              sectionId: '2983tr7283g329',
              sectionBackground: 'dough',
              description:
                data?.textAndImageContent?.description || geti18nText(language, 'menuTextAndImageDefaultDescription'),
              title: data?.textAndImageContent?.title || geti18nText(language, 'menuTextAndImageDefaultTitle'),
              eyebrow: null,
              media: {
                image: data?.textAndImageContent?.image || data?.menuItemData?.images[0],
                mediaStyle: isArch ? 'arch' : 'roundedCorners',
                mediaType: 'image',
                videoLoopDesktop: null,
                videoLoopMobile: null,
              },
              textPosition: 'right',
              cta: defaultLink,
            },
          ],
        }

        newSections.push(textAndImageData)
      }
    }

    if (data._type === 'location') {
      const locationHero = {
        section: [
          {
            _type: 'locationHero',
            _id: 'locationHero',
            areaTitle: data?.locationData?.areaTitle || '',
            city: data?.locationData?.city || '',
            cmsSettings: {
              isHidden: false,
              cmsTitle: '',
            },
            eyebrowText: data?.locationData?.eyebrowText || [],
            featuredImage: data?.locationData?.featuredImage || undefined,
            googleMapsLink: data?.locationData?.googleMapsLink || '',
            hasPrivateDining: data?.locationData?.hasPrivateDining || undefined,
            heroDescription: data?.locationData?.heroDescription || [],
            holidayHours: data?.locationData?.holidayHours || [],
            hours: data?.locationData?.hours,
            images: data?.locationData?.images || [],
            isComingSoon: data?.locationData?.isComingSoon || false,
            isReservationOnly: data?.locationData?.isReservationOnly || false,
            isTemporarilyClosed: data?.locationData?.isTemporarilyClosed || false,
            phoneNumber: data?.locationData?.phoneNumber || '',
            postalCode: data?.locationData?.postalCode || '',
            reservationLinks: data?.locationData?.reservationLinks || [],
            sectionBackground: 'dough' as SectionBackground,
            serviceLinks: data?.locationData?.serviceLinks || [],
            slug: data?.slug?.current || '',
            state: data?.locationData?.state || '',
            streetAddress: data?.locationData?.streetAddress || '',
            timeZone: data?.locationData?.timeZone || '',
            title: data?.title || '',
          },
        ],
      }

      newSections.push(locationHero)
    }

    if (data._type === 'blogPost') {
      const blogPostHero = {
        section: [
          {
            _type: 'blogPost',
            _id: 'blogPost',
            cmsSettings: {
              isHidden: false,
              cmsTitle: '',
            },
            sectionBackground: 'dough' as SectionBackground,
            title: data?.title || '',
            richTextContent: data?.richTextContent,
          },
        ],
      }

      newSections.push(blogPostHero)
    }

    if (!newSections.length) return data.sections

    const sections: SectionsSchema = [...newSections]

    if (data?.sections?.length) {
      data?.sections.forEach((section: SectionsSchema) => {
        sections.push({ section: [section] })
      })
    }

    const filteredSections = sections.filter(section => Object.keys(section.section[0]).length)

    return filteredSections
  }

  return data.sections
}

export const setCookie = ({ name, value, maxAge }: { name: string; value: string; maxAge: number }) => {
  document.cookie = `${name}=${value}; path=/; max-age=${maxAge};`
}

export const getCookie = (name: string) => {
  if (typeof document === 'undefined') {
    return null
  }

  const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'))
  if (match) return match[2]
  return null
}

export const deleteCookie = (name: string) => {
  setCookie({ name, value: '', maxAge: 0 })
}

export const replaceTextStringWithVars = (textString: string, variables: any) => {
  Object.keys(variables).forEach(variable => {
    textString = textString.replaceAll(`#{${variable}}`, variables[variable])
  })
  return textString
}

export const getMergedHoursWithHolidayHours = (
  hours: { [key: string]: OpenCloseHours },
  holidayHours?: SanityLocationHolidayHours[],
) => {
  if (!holidayHours) {
    return hours
  }

  const today = new Date()
  today.setHours(0, 0, 0, 0)
  const rangeNumber = 7
  const dayRangeDates: Date[] = []
  const holidayHoursHashedByTime: any = {}
  holidayHours.forEach(item => {
    const holidayDate = new Date(item.date)
    holidayDate.setHours(0, 0, 0, 0)
    holidayHoursHashedByTime[holidayDate.getTime()] = item
  })

  for (let index = rangeNumber * -1; index < rangeNumber; index++) {
    const date = new Date()
    date.setHours(0, 0, 0, 0)
    date.setDate(today.getDate() + index)
    dayRangeDates.push(date)
  }

  const daysByJsName: any = {
    0: 'SUNDAY',
    1: 'MONDAY',
    2: 'TUESDAY',
    3: 'WEDNESDAY',
    4: 'THURSDAY',
    5: 'FRIDAY',
    6: 'SATURDAY',
  }

  const currentDayName = daysByJsName[today.getDay() as any]
  const currentDayIndex = DAY_ORDER.indexOf(currentDayName)

  const datesByDays: any = {}
  DAY_ORDER.forEach((day, i) => {
    const difference = currentDayIndex - i
    const date = dayRangeDates[rangeNumber - 1 - difference]

    let openingClosingHours = hours[day] || { open: null, close: null }
    const holidayHashEntry = holidayHoursHashedByTime[date.getTime()]
    if (holidayHashEntry) {
      openingClosingHours = {
        open: holidayHashEntry.isClosed ? null : holidayHashEntry.openingHour,
        close: holidayHashEntry.isClosed ? null : holidayHashEntry.closingHour,
      }
    }

    datesByDays[day] = openingClosingHours
  })

  return datesByDays
}

export const getIfRestaurantIsOpen = (timeZone?: string, hours?: { [key: string]: OpenCloseHours }) => {
  if (!hours || !timeZone) return
  if (typeof hours === 'string') return

  const restaurantDateString = new Date().toLocaleString('en-US', {
    timeZone: TIME_ZONES[timeZone as keyof typeof TIME_ZONES].zone,
  })

  const restaurantDate = new Date(restaurantDateString)
  const restaurantDayName = restaurantDate.toLocaleDateString('en-US', { weekday: 'long' }).toUpperCase()
  const restaurantHoursByDay = hours[restaurantDayName as keyof typeof hours]

  if (!restaurantHoursByDay?.open || !restaurantHoursByDay?.close) return

  const restaurantOpeningDate = new Date(restaurantDateString)
  const restaurantClosingDate = new Date(restaurantDateString)
  const restaurantCurrentDate = new Date(restaurantDateString)

  const openingHoursSplit = restaurantHoursByDay?.open.split(':')
  const openingHoursHour = openingHoursSplit[0]
  const openingHoursMinutes = openingHoursSplit[1]

  const closingHoursSplit = restaurantHoursByDay?.close.split(':')
  const closingHoursHour = closingHoursSplit[0]
  const closingHoursMinutes = closingHoursSplit[1]

  restaurantOpeningDate.setHours(parseInt(openingHoursHour))
  restaurantOpeningDate.setMinutes(parseInt(openingHoursMinutes))
  restaurantOpeningDate.setSeconds(0)

  restaurantClosingDate.setHours(parseInt(closingHoursHour))
  restaurantClosingDate.setMinutes(parseInt(closingHoursMinutes))
  restaurantClosingDate.setSeconds(0)

  const restaurentCurrentTimeMs = restaurantCurrentDate.getTime()
  const restaurantOpeningTimeMs = restaurantOpeningDate.getTime()
  const restaurantClosingTimeMs = restaurantClosingDate.getTime()

  if (restaurentCurrentTimeMs > restaurantOpeningTimeMs && restaurentCurrentTimeMs < restaurantClosingTimeMs) {
    return true
  } else {
    return false
  }
}

export const formatMilitaryTime = (hourString: string) => {
  const hoursSplit = hourString?.split(':')
  if (hoursSplit?.length === 1) return hourString
  const hour = parseInt(hoursSplit[0]) ? parseInt(hoursSplit[0]) : 0
  const minutes = hoursSplit[1] ? parseInt(hoursSplit[1]) : 0

  const amPmHours = hour > 12 ? hour - 12 : hour
  const amPm = hour >= 12 ? 'PM' : 'AM'
  const amPmMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`

  return `${amPmHours}:${amPmMinutes} ${amPm}`
}

export const getRestaurantClosingHour = (timeZone?: string, hours?: { [key: string]: OpenCloseHours }) => {
  if (!hours || !timeZone) return
  if (typeof hours === 'string') return

  const restaurantDateString = new Date().toLocaleString('en-US', {
    timeZone: TIME_ZONES[timeZone as keyof typeof TIME_ZONES].zone,
  })

  const restaurantDate = new Date(restaurantDateString)
  const restaurantDayName = restaurantDate.toLocaleDateString('en-US', { weekday: 'long' }).toUpperCase()
  const restaurantHoursByDay = hours[restaurantDayName as keyof typeof hours]

  if (!restaurantHoursByDay.close) return ''

  return formatMilitaryTime(restaurantHoursByDay?.close)
}

export const getRestaurantAddressString = ({
  streetAddress,
  city,
  state,
  postalCode,
}: {
  streetAddress?: string
  city?: string
  state?: string
  postalCode?: string
}) => {
  let str = ''

  if (!streetAddress || !city || !state || !postalCode) {
    return str
  }

  if (streetAddress) {
    str += streetAddress
  }
  if (city) {
    str += `, ${city}`
  }
  if (state) {
    str += `, ${state}`
  }
  if (postalCode) {
    str += ` ${postalCode}`
  }

  return str
}

export const getFormattedDateString = (dateString: string) => {
  const dateObject = new Date(dateString)
  const date = new Date(dateObject)
  date.setDate(date.getDate() + 1)
  date.setHours(0, 0, 0, 0)
  const day = date.getDate()
  const year = date.getFullYear()
  const month = date.toLocaleString('default', { month: 'long' })

  return `${month} ${day}, ${year}`
}

export const sendEvent = (event: string, value: string) => {
  if (typeof window !== 'undefined' && GTM_ID) {
    sendGTMEvent({ event, value })
  }
}

export const getLocationListItemId = (state: string) => `id_state_${state}`

export const getPrivateDiningId = (language: string, locationSlug: string) => {
  return `${language}_${locationSlug}_${PRIVATE_DINING_SECTION_ID_SUBSTRING}`
}
